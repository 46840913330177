$light: #f5f5f5;
$dark: #000;

$brand-light: #8fbfe9;
$brand-medium: #0973BA;
$brand-dark: #015dad;

$brand-active: #d7df1f;

$gray-lighter: lighten($dark, 93.5%) 	/* #eeeeee */;
$gray-light: #cccccc 				  	/* lighten($dark, 46.7%); #777777 */;
$gray: lighten($dark, 33.5%)         	/* #555555 */;
$gray-dark: #4d4d4f 					/* lighten($dark, 20%);   #333333 */;
$gray-darker: lighten($dark, 13.5%)  	/* #222222 */;

/*	Other */
$c-error: #c00;
$c-error-border: #e09797;
$c-error-shadow: #f8b9b7;
$other-color: #f0ad4e;
