// Colors

$overlay-color: $gray-lighter;
$back-color: $gray-dark;

/* Bar container */
@mixin progress-radial($size) {
  margin: 0 auto;
  position: relative;
  width: $size;
  height: $size;
  border-radius: 50%;
  background-color: $other-color; }

@mixin overlay($size, $fontSize, $lineHeight) {
  font-family: $font-condensed;
  font-weight: bold;
  position: absolute;
  width: $size;
  height: $size;
  background-color: $overlay-color;
  border-radius: 50%;
  margin-left: 10%;
  margin-top: 10%;
  text-align: center;
  line-height: $lineHeight;
  font-size: $fontSize;
  color: $gray-dark; }

/* Mixin for progress-% class */

/* step of % for created classes */
$step: 1;

$loops: round(100 / $step);
$increment: 360 / $loops;
$half: round($loops / 2);
@for $i from 0 through $loops {
  .progress-#{$i*$step} {
    @if $i < $half {
      $nextdeg: 90deg + ( $increment * $i );
      background-image: linear-gradient(90deg, $back-color 50%, transparent 50%, transparent), linear-gradient($nextdeg, $other-color 50%, $back-color 50%, $back-color); }
    @else {
      $nextdeg: -90deg + ( $increment * ( $i - $half ) );
      background-image: linear-gradient($nextdeg, $other-color 50%, transparent 50%, transparent), linear-gradient(270deg, $other-color 50%, $back-color 50%, $back-color); } } }


// The actual classes Used
.progress-radial-big {
  @include progress-radial(90px);
  .overlay {
    @include overlay(80%, 28px, 71px); } }

.progress-radial-small {
  @include progress-radial(80px);
  .overlay {
    @include overlay(80%, 18px, 65px); } }
