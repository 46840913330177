#feedNav {
    @include standard-border($light, top);

    li {
        float: left;
        padding: 4px 12px;

        &.feeds-refresh, li.feeds-refresh {
            float: right; } }

    &.active:after {
        border: solid transparent 4px;
        border-top-color: $gray-lighter;
        float: none;
        margin-left: 38%;
        margin-top: -21px;
        position: inherit; } }

.np-feed-item {
    .panel {
        margin-bottom: 0;
        border-radius: 0;
        border: none;

        h4 {
            margin-top: 0; } }

    .panel-footer {
        padding: 0 10px 10px;
        background: none;
        border: none; }

    .close, .np-comment-item .close {
        background-image: url(/img/close.png);
        background-position: -2px -3px;
        width: 15px;
        height: 14px;
        overflow: hidden;
        line-height: 1000px;
        position: relative;
        top: -5px;
        opacity: 1;
        filter: none;
        display: none; }

    .close.is-own {
        display: block; }

    &:hover .close.is-own {
        display: block; }

    .applauders {
        @include standard-border($light, top);
        @include standard-border($gray-lighter, bottom);
        padding: 5px 5px 5px 15px;
        font-size: 12px;
        color: $gray-light;

        * {
            font-size: inherit; }

        a {
            cursor: pointer; } }

    .others {
        position: relative;

        .other-link {
            white-space: nowrap; }

        &.showpopup .applauders-popup {
            display: block; } } }

.np-feed-img img {
    width: 80px;
    height: 80px; }

.np-feed-info > p p {
    margin-bottom: 10px; }

.ro-activity {
    .np-feed-item:hover .close {
        display: none; }

    &:hover .close.is-own {
        display: block; } }

.np-feed-panel {
    clear: both;
    padding-top: 15px;
    text-align: right;

    a {
        color: $brand-medium;
        display: inline-block;
        padding: 0px 6px 0px 5px;
        @include standard-border($gray-light, right);
        font-size: 12px;
        line-height: 1;
        cursor: pointer; }

    &.inprogress {
        color: $gray-light;
        cursor: default; }

    .feed-item-date {
        float: left;
        color: $gray;
        font-size: 11px;
        text-align: center;
        margin-top: 3px;
        min-width: 100px; }

    a {
        span {
            color: inherit;
            font-size: inherit; }

        .unpplaud {
            display: none; }

        &.last {
            border-right: 0;
            padding-right: 0; }

        &.shared {
            cursor: default;
            text-decoration: none; } } }

li.feeds-refresh a .fa-refresh {
    vertical-align: inherit;
    color: $light; }

.np-comment-item {
    margin-bottom: 0;

    textarea {
        &.message {
            resize: none;

            &.focused {
                height: 50px; } } }

    &.by-user .close {
        background-image: url(/img/edit.png);
        width: 17px;
        height: 20px; }

    .close {
        display: block; }

    &.edit textarea.message {
        margin-top: 15px;
        width: 362px; } }

.np-comment-img {
    float: left;
    width: 48px;
    height: 48px;
    line-height: 46px;
    margin-right: 10px;

    img {
        max-width: 46px;
        max-height: 46px; }

    &.avatar-male-sm,
    &.avatar-female-sm {
        width: 46px;
        height: 46px; } }

.show-all-link {
    display: block;
    font-size: 12px;
    color: $gray-darker;
    background: $gray-lighter;
    padding: 5px 0;
    @include standard-border($gray-light, bottom);
    @include standard-border($gray-light, right);
    @include standard-border($gray-light, left);
    text-align: center;

    &.disabled {
        color: $gray-light;
        text-decoration: none;
        cursor: default; } }

.np-comment-info {
    float: left;
    width: 85%;
    word-wrap: break-word;

    h3 {
        font-size: 11px;
        line-height: 1;
        color: $gray-darker;
        padding: 0;
        margin: 0; }

    p {
        font-size: 12px;
        padding: 0;
        margin: 0;
        line-height: 1.2;

        a {
            text-decoration: underline; } }

    .date {
        font-size: 10px;
        color: $gray;
        line-height: 1; } }

.load-more-link {
    display: block;
    text-align: center;
    padding: 10px 0px;
    @include standard-border($gray-light, top);
    color: $light;
    width: 100%;
    background: $gray-dark;
    margin-bottom: 10px; }

.applauders-popup {
    position: absolute;
    width: 270px;
    bottom: 11px;
    padding-bottom: 11px;
    display: none;
    left: 0; }

.applauders-popup-inner {
    background-color: $light;
    padding: 10px;

    box-shadow: 0 0 2px $gray-light; }

.applauders-popup .arrow {
    position: absolute;
    bottom: 0px;
    width: 16px;
    height: 11px;
    left: 50%;
    margin-left: -8px;
    background-image: url(/img/tooltip-arrow.png); }

.feed-item-block {
    margin-bottom: 10px; }

.not-by-user .edit-comment-link {
    display: none; }

.by-user .block-user-link {
    display: none; }

.cannot-remove-others {
    .not-by-user {
        .remove-comment-link {
            display: none; }

        .row-action-popup .datatable-row-action {
            border: none; } }

    .np-comment-item .row-action-popup {
        margin-left: 230px; } }

.np-comment-item {
    .row-action-popup {
        margin: -10px 0px 0px 280px;

        .remove-comment-link {
            border-bottom: 0; } }

    &.edit p {
        display: none; } }

.np-feed-item-comments.edit .np-comment-item.message {
    display: none; }
