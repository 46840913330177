.widgets {
    text-align: center;

    i {
        padding: 5px;
        background: $light;
        color: $gray-dark;
        border-radius: 4px;
        min-width: 40px;
        text-align: center; }

    p {
        font-size: 11px;
        padding-left: 5px; } }

.wo-details-chart {
    width: 100%;
    height: 365px;
    padding-bottom: 5px;
    margin-bottom: 10px;
    background-color: $gray-lighter; }

.table-hover {
    > tbody {
        > tr:hover.odd > td,
        > tr:hover.odd > th,
        > tr.highlight.odd > td,
        > tr.highlight.odd > th,
        > tr:hover.even > td,
        > tr:hover.even > th,
        > tr.highlight.even > td,
        > tr.highlight.even > th {
            background-color: $gray-dark;
            color: $gray-lighter;
            cursor: pointer; }

        > tr > td > a {
            color: $gray-dark; }

        > tr:hover.odd > td > a,
        > tr:hover.even > td > a,
        > tr.highlight.odd > td > a,
        > tr.highlight.even > td > a {
            color: $gray-lighter; } } }

.radio-group-column {
    line-height: 1.2;
    padding: 5px 0 10px 140px;
    overflow: hidden;
    * {
        line-height: inherit; }

    input {
        float: left;
        margin: 0; } }

.modal-workout-editor {
    .modal-dialog {
        width: 90%;
        max-width: 370px; }

    .modal-body {
        text-align: left;
        padding: 0 20px; }

    h3 {
        text-transform: uppercase;
        text-align: center;
        margin: 0; } }

.modal-buttons {
    margin: 20px auto;
    padding: 0; }

.row-action-popup {
    position: absolute;
    max-width: 200px;
    padding-right: 11px;

    .arrow {
        position: absolute;
        bottom: 0;
        width: 16px;
        height: 11px;
        left: 50%;
        margin-left: -8px;
        background-image: url(/img/tooltip-arrow.png); }

    .datatable-row-action {
        display: block;
        color: $gray-dark;
        text-align: center;
        border: none;
        border-bottom: solid 1px $gray;
        font-size: 15px;
        line-height: 15px;
        padding: 5px 0;
        cursor: pointer; }

    .datatable-row-action.last {
        border: none; } }

.row-action-popup-inner {
    background-color: $light;
    padding: 10px;
    min-width: 95px;
    box-shadow: 0 0 2px $gray-light; }


.page-workouts {
    table {
        th, td {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; } } }
