@-moz-document url-prefix() {
    .design {
        div.select-element {
            select {
                padding-top: 8px; } }
        input[type="radio"] + label:before {
            line-height: 99%; } } }

select:invalid,
input:invalid {
    box-shadow: none; }


