#notVerifiedOverlay {
    background-color: $gray-darker;
    bottom: 0;
    left:  0;
    opacity: .8;
    filter: alpha(opacity=80);
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 5099; }

#notVerifiedDialog {
    text-align: center;
    position: fixed;
    top: 10%;
    margin-left: -225px;
    left: 50%;
    z-index: 5100;
    width: 90%;
    max-width: 450px; }
