.wizard-panel {
    padding: 70px 80px 0; }

#ftu, #signin-confirm {
    .page-content {
        padding-top: 20px; }

    .rf-barcode {
        display: block;
        width: 79px;
        height: 40px;
        margin: 0 10px 0 -3px;
        background: url(/img/brands/rf-barcode.png) no-repeat 0 0; } }

#hcBarcode {
    width: 60%; }

.well.colored-bg {
    background: $light url(/img/bg/signup-banner.png) no-repeat scroll 50% 20px;

    .hc-select-title {
        color: $light;
        margin-top: 85px;
        margin-bottom: 163px; }

    .profile-setup-title {
        color: $light;
        margin: 98px 0 180px; } }

#profile-setup {
    .well.colored-bg {
        overflow: hidden; } }


.brand-retro {
    .well.colored-bg {
        background-image: url(/img/brands/rf-banner.png);

        .hc-select-title {
            margin-top: 85px;
            margin-bottom: 163px; } } }

.alert {
    border: 0; }

