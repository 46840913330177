/*    Import common files    */
@import "common/mixins";
@import "common/fonts";
@import "common/layout";
@import "common/main-navbar";
@import "common/account-sprite";
@import "common/user-image";
@import "common/design/components";
@import "common/design/elements";

// should come last
@import "common/design/firefox-hacks";

a {
    cursor: pointer; }

.form-group.inline {
    display: inline-block; }

.secondary-label {
    font-weight: 400; }

.ml-15 {
  margin-left: 15px; }

.mt-5 {
  margin-top: 5px; }
