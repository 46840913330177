/* NOTE: Any edits here must also be matched against /css/brand.css and galaxy-server web/src/main/resources/cssTemplate/generalBrandingCssTemplate.css */
#home-splash-img {
    background: no-repeat url(/img/home/splash.jpg) 50% 0;
    height: 355px; }

#agreeTOS {
    margin: 0;
    width: auto !important; }

#agreeTOSLabel {
    display: inline;
    font-size: 11px; }

span.error-block {
    display: block;
    background: $brand-active;
    left: -35px;
    padding: 7px 11px;
    position: relative;
    width: 243px; }

