.page-entertainment .dataTables_scrollBody {
    background: $gray-lighter; }

table.ent-playlist {
    color: $gray;

    thead th {
        padding-top: 0;
        padding-bottom: 0; }

    tr.odd td,
    tr.even td,
    tr.odd td.dataTables_empty {
        background: $gray-lighter; }

    tr:hover td,
    tr.highlight td {
        background-color: $brand-active; } }

table.ent-library {
    @extend table.ent-playlist; }

.mode-artist .ent-library tr:hover td {
    cursor: pointer; }

.mode-song .ent-library tr:hover td,
.ent-playlist tr:hover td,
.ent-library tr:hover td.dataTables_empty,
.ent-playlist tr:hover td.dataTables_empty {
    cursor: default; }

.ent-library {
    tbody {
        .plus {
            display: none; }

        tr:hover .plus,
        tr.highlight .plus {
            display: inline; } }

    td.dataTables_empty {
        font-size: 120%;
        padding-top: 110px;
        text-align: center; } }

.ent-playlist {
    td {
        .artist {
            display: block;
            color: $gray-light;
            line-height: 1; }
        .song {
            display: block; } }

    td.dataTables_empty {
        font-size: 120%;
        padding-top: 110px;
        text-align: center; } }

.entertainment-columns {
    display: table;

    &:first-child .entertainment-column {
        @include standard-border($gray-light, top); }

    &.last .entertainment-column {
        @include standard-border($gray-light, top); } }

.entertainment-column {
    @include standard-border($gray-light, right);
    @include standard-border($gray-light, left);
    display: table-cell;
    vertical-align: top; }

.entertainment-column-space {
    display: table-cell; }

.entertainment-column-head {
    background: $gray-dark;

    h3 {
        color: $light;
        margin: 0;
        text-align: center;
        padding: 10px 0;
        font-size: 19px;
        line-height: 1; } }

.ent-subtitle {
    text-align: center;
    background: $gray-dark;
    color: $light;
    padding: 0px 7px 10px; }

.ent-search-wrap, .ent-list-props {
    background: $gray-lighter;
    padding-top: 5px;
    padding-bottom: 5px; }

.ent-count-songs {
    float: left;
    margin: 14px 10px 10px 15px; }

.ent-search-artist {
    @extend .ent-count-songs;
    color: $gray-darker;
    font-size: 12px;
    font-weight: bold;
    margin: 14px 10px 10px;
    text-align: center;
    width: 310px; }

.ent-search-list {
    width: 125px;
    padding: 0;
    line-height: 1; }
