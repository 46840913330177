.accounts-sprite,
.accounts-bmfit_linked,
.accounts-bmfit_unlinked,
.accounts-carepass_linked,
.accounts-carepass_unlinked,
.accounts-fb_linked,
.accounts-fb_unlinked,
.accounts-fitbit_linked,
.accounts-fitbit_unlinked,
.accounts-hg_linked,
.accounts-hg_unlinked,
.accounts-mb_linked,
.accounts-mb_unlinked,
.accounts-mfp_linked,
.accounts-mfp_unlinked,
.accounts-mmf_linked,
.accounts-mmf_unlinked,
.accounts-moves_linked,
.accounts-moves_unlinked,
.accounts-nikeplus_linked,
.accounts-nikeplus_unlinked,
.accounts-up_linked,
.accounts-up_unlinked,
.accounts-withings_linked,
.accounts-withings_unlinked {
  background-image: url('../img/profile/accounts-s2a503d3c20.png');
  background-repeat: no-repeat;
}

.accounts-bmfit_linked {
  background-position: 0 0;
}

.accounts-bmfit_unlinked {
  background-position: 0 -37px;
}

.accounts-carepass_linked {
  background-position: 0 -74px;
}

.accounts-carepass_unlinked {
  background-position: 0 -111px;
}

.accounts-fb_linked {
  background-position: 0 -148px;
}

.accounts-fb_unlinked {
  background-position: 0 -185px;
}

.accounts-fitbit_linked {
  background-position: 0 -222px;
}

.accounts-fitbit_unlinked {
  background-position: 0 -259px;
}

.accounts-hg_linked {
  background-position: 0 -296px;
}

.accounts-hg_unlinked {
  background-position: 0 -333px;
}

.accounts-mb_linked {
  background-position: 0 -370px;
}

.accounts-mb_unlinked {
  background-position: 0 -407px;
}

.accounts-mfp_linked {
  background-position: 0 -444px;
}

.accounts-mfp_unlinked {
  background-position: 0 -481px;
}

.accounts-mmf_linked {
  background-position: 0 -518px;
}

.accounts-mmf_unlinked {
  background-position: 0 -555px;
}

.accounts-moves_linked {
  background-position: 0 -592px;
}

.accounts-moves_unlinked {
  background-position: 0 -629px;
}

.accounts-nikeplus_linked {
  background-position: 0 -666px;
}

.accounts-nikeplus_unlinked {
  background-position: 0 -703px;
}

.accounts-up_linked {
  background-position: 0 -740px;
}

.accounts-up_unlinked {
  background-position: 0 -777px;
}

.accounts-withings_linked {
  background-position: 0 -814px;
}

.accounts-withings_unlinked {
  background-position: 0 -851px;
}
