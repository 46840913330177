#user-goal-nav {
    background: $light;
    list-style-type: none;
    margin: 0;
    width: 100%;
    overflow: hidden;
    margin-bottom: 10px;
    padding-left: 0;

    li {
        color: $gray-dark;
        cursor: default;
        float: left;
        font-family: $font-condensed;
        height: 50px;
        line-height: 50px;
        width: 25%;
        position: relative;
        text-align: center;

        first-child: {
            font-weight: bold; }

        &.active {
            background: rgb(231,231,231) /* Old browsers */;
            /* IE9 SVG, needs conditional override of 'filter' to 'none' */
            background: url(data:image/svg+xmlbase64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2U3ZTdlNyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjgyJSIgc3RvcC1jb2xvcj0iI2U3ZTdlNyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
            background: -moz-linear-gradient(left,  rgba(231,231,231,1) 0%, rgba(231,231,231,1) 82%, rgba(255,255,255,1) 100%) /* FF3.6+ */;
            background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(231,231,231,1)), color-stop(82%,rgba(231,231,231,1)), color-stop(100%,rgba(255,255,255,1))) /* Chrome,Safari4+ */;
            background: -webkit-linear-gradient(left,  rgba(231,231,231,1) 0%,rgba(231,231,231,1) 82%,rgba(255,255,255,1) 100%) /* Chrome10+,Safari5.1+ */;
            background: -o-linear-gradient(left,  rgba(231,231,231,1) 0%,rgba(231,231,231,1) 82%,rgba(255,255,255,1) 100%) /* Opera 11.10+ */;
            background: -ms-linear-gradient(left,  rgba(231,231,231,1) 0%,rgba(231,231,231,1) 82%,rgba(255,255,255,1) 100%) /* IE10+ */;
            background: linear-gradient(to right,  rgba(231,231,231,1) 0%,rgba(231,231,231,1) 82%,rgba(255,255,255,1) 100%) /* W3C */;
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e7e7e7', endColorstr='#ffffff',GradientType=1 ) /* IE6-8 */; }

        &.selected {
            cursor: pointer;
            background: $gray-lighter; } } }

#goal-body {
    min-height: 336px;
    overflow: auto; }

.goal-wizard-block {
    .inner {
        min-height: 206px;
        margin-bottom: 20px;
        border-radius: 2px;

        h2 {
            padding-top: 25px;
            font-size: 54px;
            font-weight: bold; }

        .fa {
            margin: 30px 0 10px; }

        .info {
            margin-top: 20px; }

        &:hover {
            background: $gray-lighter;
            color: $gray-dark; } } }

ul.goal-wizard li.customTarget {
    cursor: default; }

form.customTargetForm {
    position: relative;

    span.np-unit-per-interval {
        display: block;
        position: relative;
        top: 7px; }


    input {
        text-align: center;
        margin: 12px 0 30px;
        padding: 10px 0px;

        @include transition(0.2s); } }

li.overed form.customTargetForm input {
    margin: 10px 0px 1px; }

form.customTargetForm .tooltip {
    margin-top: 12px;

    @include transition(0.2s); }

li.overed form.customTargetForm .tooltip {
    margin-top: 0; }

form.customTargetForm .tooltip-inner {
    position: relative;
    top: -1px;
    font-size: 11px; }

/* goal center styles */
.goal-info-panel {
    overflow: hidden;
    padding: 5px 0px; }

.goal-progress-wrapper {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    position: relative; }

.goal-progress-item {
    padding-bottom: 20px;

    h4 {
        text-align: center; }

    .columns {
        display: table;
        width: 100%;
        position: relative; }

    .column {
        display: table-cell;
        height: 70px;
        vertical-align: middle;
        text-align: center;
        font-size: 18px;
        overflow: hidden;
        white-space: nowrap;
        width: 1%;
        background: $gray-dark;
        color: $light;

        span {
            color: $light;
            height: 70px;
            line-height: 70px;
            display: block;
            cursor: default;
            padding: 0 2px; } }

    .tooltip-hide .tooltip {
        display: none !important; }


    .column.rest {
        background: $gray-lighter;
        width: 99%;
        padding: 0 3px; }


    .column.complete {
        width: 1%;
        background: $gray-dark; } }

.columns .tooltip-left .tooltip-inner {
    position: relative;
    left: 35%;
    min-width: 15px;
    border-radius: 0 4px 4px 4px; }

.column-collapsed:hover {
    border-color: $gray-lighter; }

/* Dashboard */
.goal-center-message {
    position: relative;
    text-align: center;
    padding: 20px;
    margin: 10px 0;
    border: 1px solid $gray-light;
    background: $gray-lighter url(/img/goals/goal-completed-back.jpg);

    &.inprogress {
        background-image: none; }

    h3 {
        color: $gray-light;
        text-align: center;
        font-size: 28px;
        line-height: 28px;
        margin: 0 0 4px;
        font-weight: normal;
        text-transform: uppercase; }

    &.expired h3 {
        color: red; }

    .info {
        color: green;
        font-size: 40px;
        line-height: 40px;
        margin-bottom: 15px;
        text-transform: uppercase; }

    .close {
        position: absolute;
        top: 7px;
        right: 7px;
        width: 19px;
        height: 19px;
        opacity: .7;
        filter: alpha(opacity=70);
        background: url(/img/close.png) 0 -19px no-repeat;

        &:hover {
            opacity: 1;
            filter: alpha(opacity=100); } } }

.goal-wizard-buttons {
    height: 40px;
    .pager {
        margin: 0; } }

/* progress bar styles */
div.progress-label-container {
    position: relative;
    z-index: 100;
    color: $gray-darker;
    font-size: 11px;
    width: 280px;

    div.progress-label {
        float: left;
        height: 30px; }

    div.arrow {
        display: none; } }

div.progress-label {
    cursor: pointer;

    div.label {
        background: none;
        color: inherit;
        float: right;
        font-size: 14px; }

    &.active {
        cursor: default;
        position: relative;

        div.label {
            background: $gray-darker;
            color: $light; }

        div.arrow {
            border: solid transparent 4px;
            border-top-color: $gray-darker;
            color: $light;
            display: block;
            float: right;
            height: 0;
            left: 16px;
            position: relative;
            top: 16px;
            width: 0; } } }

div.progress {
    background: $gray-lighter;
    height: 9px;
    overflow: hidden;
    width: 100%; }

div.hash-container {
    height: 9px;
    position: absolute;
    width: 280px;
    z-index: 10;

    div {
        border-right: solid 1px $gray-light;
        float: left;
        height: 9px; } }

div.meter {
    background-color: orange;
    float: left;
    height: 9px;
    left: 0;
    position: relative;
    z-index: 20; }
