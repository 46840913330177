.challenge-details-page {
    .ch-numbers {
        div {
            font-size: 28px; } }
    .challenge-info {
        width: 100%;
        th {
            text-align: right;
            padding-right: 15px;
            white-space: nowrap;
            vertical-align: top; }
        tr.hr {
            th, td {
                padding-bottom: 20px;
                border-bottom: 1px solid $gray-lighter; }
            & + tr {
                th, td {
                    padding-top: 20px; } } } } }

.ch-subtitle {
    font-weight: bold;
    text-align: center;
    background: $gray-dark;
    padding: 10px;
    color: $light;
    text-transform: uppercase;
    overflow: auto;

    a {
        color: $light;
        text-decoration: none;
        &:hover {
            color: $brand-active; } } }

.ch-description {
    height: 180px;
    overflow: hidden; }

.ch-image {
    display: block;
    text-align: center;
    margin-bottom: 10px; }

.ch-challenge-list .ch-done {
    opacity: .75;
    filter: alpha(opacity=75); }

table.ch-participants-table td > .user-avatar,
.user-avatar-inline-small {
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    margin-right: 4px; }

table.ch-participants-table .user-avatar .userImg,
.user-avatar-inline-small .userImg {
    border: none;
    box-shadow: none;
    visibility: visible;
    transform: translate(0.203, 0.203); }

.ch-user-indentificator {
    position: absolute;
    height: 43px;
    line-height: 23px;
    padding: 10px 15px 10px 10px;
    background: $gray-lighter;
    margin: -10px 0 0 -71px;
    text-align: right;
    color: $gray-dark;
    cursor: default;
    @include border_radius(8px 0px 0px 8px); }

