body {
    color: $gray-darker; }

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    line-height: 1.5;
    font-family: $font-condensed;
    font-weight: bold;
    .secondary {
        color: $gray-light; } }

h5 {
    text-transform: uppercase; }

.lead {
    font-size: 18px; }

#wrap > .container {
    padding-top: 100px; }

.border-left {
    > div {
        border-left: 1px solid $gray-light; }

    div:first-child {
        border: none; } }

.btn:focus, .btn:active:focus, .btn.active:focus {
    outline: 0; }

.panel-body > a.userImg {
    margin: 0 25px; }

#nav-container {
    background-color: $gray-dark;
    min-height: 40px;
    margin: 0;
    padding-left: 10px;
    color: $light;
    font-size: 12px;
    font-weight: bold;
    line-height: 40px;

    li {
        cursor: pointer;
        display: inline-block;
        position: relative;
        padding-right: 25px;

        &.active {
            color: $brand-active;
            cursor: default;

            &:after {
                background: transparent;
                border: solid transparent 4px;
                border-bottom-color: $gray-lighter;
                color: $light;
                content: '';
                display: block;
                float: right;
                height: 0;
                left: 44%;
                position: absolute;
                top: 32px;
                width: 0; } } } }

@media (min-width: 1200px) {
    .container {
        width: 970px; } }

@media (max-width: 767px) {
    .border-left {
        > div {
            border-left: none; } }

    #wrap > .container {
        padding-top: 80px; }

    .navbar {
        overflow: auto;
        min-height: 60px;

        #brand-logo {
            margin: 0 auto; }

        .open .dropdown-menu {
            width: 100%;
            background-color: $light;
            position: inherit;
            overflow: auto; } }

    .navbar-default {
        .navbar-toggle {
            margin-top: 14px; }

        .navbar-nav > li > a {
            line-height: 27px;
            height: 45px; } }

    #notVerifiedDialog {
        width: 90%;
        margin-left: -45%; }

    #nav-container.nav-fluid {
        overflow: auto;
        li {
            float: left;
            clear: left;

            &.active:after {
                display: none; } } }

    .modal {
        .modal-header {
            padding: 5px;

            h3 {
                margin: 0;
                font-size: 14px; } }

        .modal-body {
            font-size: 13px;
            padding: 10px; }

        .modal-buttons {
            margin: 10px; } }

    // Subnav
    #nav-container {
        font-size: 11px;
        li {
            padding-right: 10px; } } }

div.dialog-close:before {
    cursor: pointer;
    display: block;
    position: absolute;
    top: 0px;
    right: 5px;
    font-family: FontAwesome;
    content: "\f05c";
    font-size: 20px; }

.modal {
    bottom: inherit; }

.well {
    border: none;
    box-shadow: none;
    border-radius: 0;

    h2 {
        margin: 0; } }

h3 {
    margin-top: 0; }

.form-group {
    margin-right: 0;
    margin-left: 0; }

.no-top-radius {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px; }

.well-dark {
    color: $light;
    background-color: $gray-lighter; }

.well-darker {
    color: $light;
    background-color: $gray-dark; }

.well-no-pad {
    padding: 0; }

.no-marg-bot {
    margin-bottom: 0; }

.dataTables_wrapper {
    .row {
        margin-right: 0;
        margin-left: 0; } }

.entertainment-plus {
    .banner {
        background-color: $gray-dark;
        color: $light;
        margin-bottom: 20px;

        i.fa-inverse {
            color: $gray-dark; } }

    .nav-tabs > li.active > a {
        border-top: 1px solid $gray-light;
        border-left: 1px solid $gray-light;
        border-right: 1px solid $gray-light; }

    .tab-content {
        background-color: #fff;
        padding: 20px 0;
        border-bottom: 1px solid $gray-light;
        border-left: 1px solid $gray-light;
        border-right: 1px solid $gray-light; }

    #termsModal {
        .modal-content .modal-body {
            height: 400px;
            overflow: auto;
            text-align: left; } }

    .feedbackBox {
        min-height: 65px;

        .alert {
            margin-bottom: 0; } } }

.dataTables_info {
    float: left; }

.dataTables_paginate {
    float: right;

    span a {
        padding: 0 5px;

        &.paginate_active {
            color: $gray-dark; }

        &:hover {
            text-decoration: none;
            color: $gray-dark; } } }

.dataTables_scrollHead {
    display: none; }

