// in alphabeta order

@mixin bg_padding($side, $bgpx, $padpx) {
	background-position: $side, $bgpx;
	padding-#{$side}: $padpx; }

@mixin border_radius($x...) {
	-webkit-border-radius: $x;
	-moz-border-radius: $x;
	border-radius: $x; }

@mixin border-side-radius($side, $px) {
	border-#{$side}-radius: $px; }

@mixin controls {
	padding: 4px 6px;
	line-height: 20px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	border: 1px solid $gray-light; }

@mixin positioning($pos, $px, $display) {
	position: $pos;
	top: $px;
	right: $px;
	bottom: $px;
	left: $px;
	display: $display; }

@mixin standard-border($color, $side: "") {
	@if $side != "" {
		border-#{$side}: 1px solid $color; }
	@else {
		border: 1px solid $color; } }

@mixin transform($x, $y) {
	-webkit-transform: scale($x, $y);
	-moz-transform: scale($x, $y);
	-ms-transform: scale($x, $y);
	-o-transform: scale($x, $y);
	transform: scale($x, $y); }

@mixin transition($x) {
	transition: margin $x;
	-moz-transition: margin $x;
	-webkit-transition: margin $x;
	-o-transition: margin $x; }
