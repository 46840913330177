/* np-bootstrap-dropdown */

.btn-group.np-dropdown .btn .caret {
    float: right; }

.dataTables_processing {
    @include positioning(absolute, 0, block);
    padding: 10px;
    background: $light;
    opacity: 0.5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";

    .datatable-loader-img {
        background: url('/img/bg/loading_middle.gif') no-repeat center center;
        text-align: center; } }

/* buttons */
.buttons-group {
    margin: 10px 10px 10px 15px;
    overflow: hidden;
    float: left;

    a {
        float: left;
        background: $gray;
        font-size: 12px;
        font-weight: bold;
        height: 22px;
        line-height: 22px;
        padding: 0px 5px;
        min-width: 65px;
        text-align: center;
        color: $light;
        text-shadow: 0px 1px 1px $gray-darker; }

    &:first-child {
        @include border_radius(4px 0 0 4px); }

    &:last-child {
        @include border_radius(4px 0 0 4px); }

    &.active {
        background: $gray-darker;
        color: $brand-active;
        text-decoration: none;
        cursor: default; } }

.pager-buttons {
    float: right; }

/* dropdown list */
.dropdown-menu {
    margin-top: 2px; }

.np-dropdown {
    margin: 0;
    padding: 0;
    border: none;

    ul,
    a,
    li > a,
    ul.typeahead,
    ul.typeahead li > a {
        background: $gray;
        color: $light;
        font-size: inherit;
        font-weight: inherit; }

    ul,
    ul.typeahead {
        @include border_radius(0); }

    .btn .caret {
        border: none;
        margin-top: 3px;
        width: 14px;
        height: 14px;
        background: url(/img/icons/arrows.png) no-repeat 0 0 transparent; }

    .btn:hover {
        color: $light; }

    li a:focus,
    ul.typeahead > li.active,
    li:hover,
    ul.typeahead > li:hover {
        background: $gray; }

    &.open .btn .caret {
        background-position: 0 -42px; }

    li:active {
        border: solid 1px $gray-darker;
        background: $gray; }

    &.btn-group .btn.dropdown-toggle:hover {
        background: $gray; } }

.np-dropdown li > a:hover,
.typeahead.dropdown-menu li > a:hover,
.typeahead.dropdown-menu li.active > a {
    background: none; }


.np-dropdown.btn-group.open .btn.dropdown-toggle {
    background: $gray;
    text-decoration: none;

    box-shadow: none; }

/* modal window */
.modal {
    .modal-dialog {
        background: $light;
        @include border-radius(8px); }

    .modal-content {
        background: $light; }

    .modal-body {
        text-align: center;
        font-size: 13px;
        padding: 15px 10px 10px; }

    .modal-header {
        h3 {
            margin: 0;
            text-align: center;
            text-transform: uppercase; } }

    .dont-ask {
        margin-top: 30px;

        label {
            font-weight: normal; } } }

@media (min-width: 768px) {
    .modal-dialog {
        width: 450px;

        &.modal-sm {
            width: 300px; } } }

div.modal-confirm span.title,
div.modal-confirm-unblock span.title {
    display: block;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    margin: 27px 0 18px 0; }

.modal-buttons {
    text-align: center;
    padding: 20px 0; }

.modal-button {
    font-size: 12px; }

.modal-message {
    top: 10%;
    width: 360px;
    height: 180px;
    margin: 0 auto;

    .modal-body {
        text-align: center;
        font-size: 13px;
        padding: 15px 10px 10px; } }

div.column-title {
    background-color: $gray-dark;
    border-bottom: 1px solid $gray;
    border-top: 1px solid $light;
    color: $light;
    height: 23px;
    line-height: 23px;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    font-weight: normal;
    margin: 0;

    h3 {
        font-size: 14px;
        font-weight: normal;
        line-height: 25px;
        margin: 0;
        letter-spacing: 1px; } }

.control-hint {
    margin-left: 20px; }

/* np-bootstrap-popover */
.popover {
    width: 260px; }

.popover-content {
    padding: 8px;
    font-size: 13px; }

.popover-title {
    display: none; }

.popover-custom-mask {
    border: 0;
    margin: 0;
    padding: 0;
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    opacity: 0;
    z-index: 9998;
    /*styles required for IE to work*/
    background-color: $light;
    filter: alpha(opacity=0); }

/*   Error provider   */
.error-provider {
    position: fixed;
    width: 200px;
    height: 100px;
    top: 0;
    left: 0;

    .error-provider-item {
        color: $c-error;
        background-color: $gray-lighter;
        border-color: $c-error-border;
        padding: 8px 10px 8px 14px;
        margin-bottom: 20px;
        text-shadow: 0 1px 0 $gray-lighter;
        border: 1px solid $gray-lighter;
        @include border_radius(4px); }

    h5 {
        margin: 0; } }

/* xid label */
.xid {
    background: url(/img/xid.png);
    color: transparent;
    display: block;
    font-size: 0;
    position: absolute;

    &.small {
        height: 19px;
        margin: 13px 0 0 -42px;
        width: 35px; }


    &.inline {
        height: 19px;
        width: 35px;
        display: inline-block;
        position: static;
        vertical-align: top; }


    &.large {
        background-position: 0 -20px;
        margin: 8px 5px 0 0;
        height: 24px;
        padding: 0;
        width: 50px; } }

label.control-label span.xid.large {
    float: left;
    position: relative;
    top: -3px;
    left: 54px; }

.loading-mask {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    opacity: .85;
    filter: alpha(opacity=85);
    background-color: $gray-lighter;

    i {
        position: absolute;
        font-size: 150px;
        top: 20%;
        left: 50%;
        margin-left: -75px; } }

.alert {
    overflow: hidden;
    background-color: $light;
    border-color: $gray-light;

    strong {
        padding: 10px 0px 0px 15px;
        float: left;
        color: $dark; } }

.width0 {
    display: none !important; }
