.facebook-icon-small {
    display: inline-block;
    width: 18px;
    height: 18px;
    background: #5f78ab url(/img/fb/icons/fb-login-icon-18px.png) no-repeat 0 0;
    vertical-align: text-bottom; }

.design {
    font: 14px/150% normal $font;
    color: $gray;

    focus: {
        outline: none; }

    h1, h2, h3 {
        line-height: 125%;
        .secondary {
            color: $gray-light; } }

    h1 {
        font-size: 24px; }

    h2 {
        font-size: 22px; }

    h3 {
        font-size: 18px; }

    p {
        font-size: 12px;
        line-height: 125%; }

    strong {
        font-weight: bold; }

    .title, .subtitle {
        text-align: center;
        margin: 0;
        font-family: $font;
        font-weight: normal; }

    .title {
        color: $light;
        font-size: 14px;
        line-height: 22px; }

    .subtitle {
        color: $gray-light; }

    .help, .small-text {
        font-size: 14px; }

    .page-head {
        position: relative;
        .dropdown {
            position: absolute;
            top: 0;
            right: 0; }

        .dropdown-button {
            padding: 0 0 0 18px;
            font-size: 13px;
            line-height: 42px;
            background: $brand-dark;
            .caret {
                position: absolute;
                right: 0;
                padding: 0;
                margin: 0;
                font-size: 12px;
                text-align: center;
                color: $light;
                border: none;
                background: $brand-medium; } }

        .dropdown-menu {
            margin: 0;
            padding: 0;
            max-height: 410px;
            overflow-y: scroll;
            background: $brand-dark;
            li {
                padding: 10px 0 10px 18px;
                margin: 1px 0 0;
                font-size: 13px;
                font-family: $font;
                background: $brand-dark;

                &:hover {
                    background: $brand-dark; } } } }

    .page-head {
        border: none;
        margin: 0;
        padding: 10px;
        background: $gray-darker; }

    .page-content {
        padding: 40px 20px 15px;

        &.bottom-shadow {
            box-shadow: 0 6px 4px -3px $gray-lighter; } }

    .page-top-bar {
        background: $light;
        margin: -40px -20px 30px;
        padding: 20px 20px 15px;
        min-height: 38px;
        box-shadow: 0 6px 4px -3px $gray-lighter;

        .alert {
            margin: 0; } }

    label, .form-label {
        &.control-label {
            display: inline-block;
            margin-right: 10px;
            font-weight: bold;
            color: $gray; } }

    input[type=radio] {
        display: none;

        & + label[class*=span] {
            margin: 0; }


        & + label:before {
            content: '\f111';
            display: inline-block;
            width: 20px;
            height: 20px;
            @include border-radius(12px);
            padding: 3px;
            margin: 0 5px 0 0;
            font: 11px/100% FontAwesome;
            color: transparent;
            border: 2px solid $gray-lighter; }

        &:checked + label:before {
            color: $brand-medium;
            border-color: $brand-light; } }

    input[type=checkbox] {
        display: none;

        & + label:before {
            content: '\e67a';
            display: inline-block;
            width: 20px;
            height: 20px;
            @include border-radius(4px);
            padding: 2px;
            margin: 0 6px 0 2px;
            font: 20px/6px 'Elusive-Icons';
            color: transparent;
            border: 2px solid $gray-lighter; }

        &:checked + label:before {
            color: $brand-medium;
            border-color: $brand-light; } }

    input[type=text],
    input[type=number],
    input[type=email],
    input[type=password],
    textarea {
        height: 40px;
        line-height: 40px;
        box-sizing: border-box;
        margin: 0;
        padding: 0px 1em;
        border: 2px solid $gray-lighter;
        border-radius: 4px;
        background-color: $light;
        background-image: none;
        -webkit-appearance: none;
        box-shadow: none !important;
        outline: none !important;
        @include transition(border linear .2s);
        &[disabled],
        &:focus {
            color: $gray;
            border-color: $brand-light; } }

    textarea {
        padding-top: 7px;
        padding-bottom: 7px;
        height: auto;
        line-height: 150%; }


    .input-group .input-group-addon {
        border: 2px solid $gray-lighter;
        background-color: transparent; }

    .input-group .input-group-addon,
    .input-group .btn,
    .input-group .btn-group {
        margin-left: -2px;
        margin-right: -2px; }

    .input-group .input-group-addon:first-child,
    .input-group .btn:first-child {
        margin-right: -2px; }

    div.select-element {
        position: relative;
        box-sizing: border-box;
        border: 2px solid $gray-lighter;
        @include border-radius(4px);
        overflow: hidden;
        &:before {
            content: '\e706';
            position: absolute;
            right: 0;
            top: 0;
            padding-right: 2px;
            font: 9px/36px 'Elusive-Icons';
            text-align: center;
            color: $brand-medium;
            border-left: 1px solid $gray-lighter; }

        select {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 5;
            padding: 0 0 0 1em;
            margin: 0;
            background-color: transparent;
            background-image: none;
            border: none;
            box-shadow: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            outline: none;
            text-indent: .01px;
            text-overflow: '';
            &::-ms-expand {
                display: none; }
            &[disabled] {
                background-color: $gray-lighter; } } }

    .form-actions {
        padding: 20px 20px 40px;
        text-align: left;
        background: transparent;
        border: none; } }


.ui-select-toggle {
    text-align: left; }
