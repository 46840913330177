
.login-incomplete-modal {
    &.modal {
        .modal-body {
            padding: 0 20px 20px;
            text-align: left; }

        label {
            width: 120px;
            text-align: left;
            font-size: 12px;
            font-weight: bold; }

        select.input-large {
            width: 300px; }

        form {
            margin-top: 20px; } } }

