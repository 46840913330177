.legal-page {
    h1, h2, h3, h4, h5, h6 {
        word-wrap: break-word; }

    ul > li, ol > li {
        margin: 0 0 10px; }

    .offset:before {
        content: '';
        display: block;
        position: relative;
        width: 0;
        height: 80px;
        margin-top: -80px; }

    ol.ordered-bold {
        counter-reset: item;

        li {
            display: inline-block;

            h3, h4, h5 {
                display: inline-block;
                text-transform: none; } }

        li:before {
            content: counter(item) ". ";
            counter-increment: item;
            font-weight: bold; } }

    .indent-left {
        padding-left: 40px; }

    .list-item-title {
        display: inline-block;
        font-weight: bold;
        margin-bottom: 10px; }
    table {
        border-collapse: collapse;
        border: 2px solid rgb(200,200,200);
        letter-spacing: 1px;
        font-size: 1rem; }

    td {
        border: 1px solid rgb(190,190,190);
        padding: 10px 20px;
        text-align: center; }

    .td-first {
       background-color: #8a99a3; }
    .header-center {
        text-align: center; } }
