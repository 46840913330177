.profile-info div.userImgContainer {
    zoom: 0.83;
    -moz-transform: scale(0.83);
    transform: translate(0, 0); }

// TODO: move this section to somewhere higher
#profile-container, #ftu, #profile-setup {
    .actions {
        overflow: hidden;
        margin-bottom: 15px;

        .successEl {
            opacity: 0;
            text-align: right;
            line-height: 26px;
            float: right; }

        button {
            display: block;
            float: right;
            margin-left: 15px; } }

    .singleSettingContainer {
        display: table; }


    div.controls div.status {
        font-size: 12px;
        margin-top: 2px; }

    #settings-stats {
        background-color: transparent;
        border: none;
        padding-top: 0; }

    .control-label {
        font-weight: bold;
        font-size: 13px;
        text-align: left;

        &.xid {
            font-size: 0; } }

    .controls {
        input {
            @include controls; }

        select {
            @include controls;
            height: 32px; } } }

div.form-group span.error {
    color: red;
    display: block;
    font-size: 12px;
    padding-left: 4px; }

span.tooShort, span.tooLong {
    color: orange;
    display: none; }

.match {
    color: green; }

.mismatch {
    color: orange; }



#settings-label {
    color: $light;
    cursor: default;
    position: relative;
    text-shadow: 1px 1px $gray-light;

    &:hover {
        cursor: default;
        text-decoration: none !important; } }

div.privacy-radio input, div.settings-cb input {
    margin: 0; }

div.privacy-text label, div.settings-text label {
    font-weight: bold; }

#settings-account-linking {
    div {
        a {
            float: left;
            padding: 0 5px;
            color: $gray-light; }

        &.link-container {
            border: solid 1px $gray-light; }

        &.logo-container {
            min-width: 100%;
            margin: 10px 91px; }

        &.logo {
            height: 38px; }

        > div.popover {
            line-height: 16px;

            .popover-content {
                padding: 4px 6px; }

            .popover-icon-wrapper {
                margin: 2px 0 4px 2px; } } }

    li.header {
        background-color: $gray-darker;
        border: none;
        color: $light;
        font-size: 14px;
        text-shadow: 0 1px $dark;
        height: 30px;
        line-height: 10px; } }

#settings-profile {
    select {
        width: auto; }

    div {
        &.form-inline {
            position: relative;
            top: 4px;

            input {
                margin: -1px 4px 0 0; }

            label {
                margin-right: 6px; } }

        &.controls.input-group {
            margin-left: 20px; }

        &.input-group input {
            text-align: right;
            width: 60px; } } }

#profile-nav #profileStatsLink {
    background: url(/img/profile/profileSettingsSprite.png) 0 -264px;
    float: right;
    height: 15px;
    left: -12px;
    position: relative;
    top: -1px;
    width: 19px; }

.avatar-dialog {
    .modal-dialog {
        width: 660px; } }

.primary-well {
    background-color: rgba(#ccc, .7); }

ul.avatarTray {
    list-style-type: none;
    padding-left: 0;

    li {
        margin-left: 8px;
        visibility: visible;

        &.userImg {
            border: solid 2px $light; }

        &.userImg:hover,
        &.userImg.selected {
            border-color: $brand-active; } } }
