$flag-layout: vertical;

.locale-selector {
  position: relative;
  display: flex;
  margin: 0 20px;
  cursor: pointer;
  &:after {
    content: '\25B4';
    margin-left: 3px;
  }
  ul {
    position: absolute;
    bottom: 22px;
    top: auto;
    left: auto;
    right: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    height: 61px * 5;
    width: 190px;
    font-family: $font;
    font-size: 12px;
    overflow-y: scroll;
    border-radius: 3px;
    li {
      padding: 20px;
      background: #fff;
      color: black;
      font-size: 14px;
      &:hover {
        background: #f5f5f5;
      }
      &[disabled] {
        background: #f5f5f5;
        cursor: auto;
      }
      .flag {
        margin-left: 0;
        transform: none;
      }
    }
  }
  .flag {
    display: inline-block;
    width: 18px;
    height: 12px;
    margin-left: 4px;
    margin-right: 4px;
    transform: scale(0.8, 0.8) translate(0, 2px);
  }
}
