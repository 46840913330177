.design-btn {
    display: inline-block;
    font-family: $font;
    border: 1px solid $gray-lighter;
    @include border-radius(4px);
    background-color: $gray-lighter;
    background-image: none;
    padding: 9px 14px;
    text-decoration: none;
    &:hover {
        text-decoration: none; }

    i {
        margin-right: 5px; }

    &.btn-fixed-width {
        width: 100px; }

    &.btn-blue,
    &.btn-primary {
        color: $light;
        background-color: $brand-dark;
        border-color: $brand-dark; }

    &.btn-secondary {
        color: $light;
        background-color: $gray;
        border-color: $gray; }

    &.btn-link {
        color: $brand-medium;
        text-decoration: underline;
        background-color: transparent;
        border-color: transparent; }

    &.btn-white {
        color: $gray;
        background-color: $light; }

    &.disabled,
    &[disabled],
    &[disabled]:hover {
        color: $gray;
        background-color: $gray-lighter;
        border-color: $gray-light;
        text-shadow: none;
        cursor: not-allowed; } }


.design {
    &.well,
    .well {
        background: $light;
        box-shadow: none; }

    .select2-container {
        max-width: none;
        height: 46px; }

    .select2-choice {
        height: 46px; }

    .form-inline,
    .form-actions {
        .design-btn + .design-btn {} }

    .headless .nav-tabs {
        display: none; } }

[datepicker] {
    padding: 0 5px;
    &>table {
        &:focus {
            outline: none; }

        .btn-info span {
            color: white; } } }
