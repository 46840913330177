@mixin avatar($num) {
    background: url(/img/avatars/avatar#{$num}-lg.png) 50% 0;
    height: $large-height;
    width: $large-width; }

$normal-width: 118px;
$normal-height: 118px;
$large-width: 174px;
$large-height: 198px;
$small-width: 46px;
$small-height: 46px;

.userImg {
    visibility: hidden;
    float: left;
    height: $normal-height;
    width: $normal-width;

    &.avatar-female {
        background-image: url(/img/avatars/gendered.png);
        background-position: 0 (-$large-height*2); }

    &.avatar-male {
        background-image: url(/img/avatars/gendered.png);
        background-position: -$normal-width (-$large-height*2); }

    &.avatar-url,
    &.avatar-load {
        width: $normal-width;
        height: $normal-height;
        line-height: $normal-height; }

    &.avatar-url-lg,
    &.avatar-load-lg {
        width: $large-width;
        height: $large-height;
        line-height: $large-height; }

    &.avatar-female-lg {
        background-image: url(/img/avatars/gendered.png);
        background-position: -8px 0;
        height: $large-height;
        width: $large-width; }

    &.avatar-male-lg {
        background-image: url(/img/avatars/gendered.png);
        background-position: -11px (-$large-height);
        height: $large-height;
        width: $large-width; }

    &.none {
        background-image: url(/img/avatars/avatar.png); } }

.avatar-url img {
    width: $normal-width; }

.avatar-url-lg img {
    width: 100%; }

.avatar-load,
.avatar-load-lg {
    background: transparent url(/img/bg/loading_middle.gif) no-repeat 50% 50%; }

.avatar-female-sm {
    background-image: url(/img/avatars/gendered.png);
    background-position: 0 (-$normal-height - $large-height*2 - 2px);
    height: $small-height;
    width: $small-width; }

.avatar-male-sm {
    background-image: url(/img/avatars/gendered.png);
    background-position: (-2px-$small-width) (-$normal-height - $large-height*2 - 2px);
    height: $small-height;
    width: $small-width; }

/*TODO convert to multiples of appropriate variables or make a sprite*/
.avatar0 {
    background: url(/img/avatars/avatarSprite.jpg) 0 0; }
.avatar1 {
    background: url(/img/avatars/avatarSprite.jpg) -118px 0; }
.avatar2 {
    background: url(/img/avatars/avatarSprite.jpg) -236px 0; }
.avatar3 {
    background: url(/img/avatars/avatarSprite.jpg) -354px 0; }
.avatar4 {
    background: url(/img/avatars/avatarSprite.jpg) -472px 0; }
.avatar5 {
    background: url(/img/avatars/avatarSprite.jpg) 0 -118px; }
.avatar6 {
    background: url(/img/avatars/avatarSprite.jpg) -118px -118px; }
.avatar7 {
    background: url(/img/avatars/avatarSprite.jpg) -236px -118px; }
.avatar8 {
    background: url(/img/avatars/avatarSprite.jpg) -354px -118px; }
.avatar9 {
    background: url(/img/avatars/avatarSprite.jpg) -472px -118px; }
.avatar10 {
    background: url(/img/avatars/avatarSprite.jpg) -590px 0; }
.avatar11 {
    background: url(/img/avatars/avatarSprite.jpg) -708px 0; }
.avatar12 {
    background: url(/img/avatars/avatarSprite.jpg) -826px 0; }
.avatar13 {
    background: url(/img/avatars/avatarSprite.jpg) -944px 0; }
.avatar14 {
    background: url(/img/avatars/avatarSprite.jpg) -1062px 0; }
.avatar15 {
    background: url(/img/avatars/avatarSprite.jpg) -590px -118px; }
.avatar16 {
    background: url(/img/avatars/avatarSprite.jpg) -708px -118px; }
.avatar17 {
    background: url(/img/avatars/avatarSprite.jpg) -826px -118px; }
.avatar18 {
    background: url(/img/avatars/avatarSprite.jpg) -944px -118px; }
.avatar19 {
    background: url(/img/avatars/avatarSprite.jpg) -1062px -118px; }

.avatar0-lg {
    @include avatar(0); }
.avatar1-lg {
    @include avatar(1); }
.avatar2-lg {
    @include avatar(2); }
.avatar3-lg {
    @include avatar(3); }
.avatar4-lg {
    @include avatar(4); }
.avatar5-lg {
    @include avatar(5); }
.avatar6-lg {
    @include avatar(6); }
.avatar7-lg {
    @include avatar(7); }
.avatar8-lg {
    @include avatar(8); }
.avatar9-lg {
    @include avatar(9); }
.avatar10-lg {
    @include avatar(10); }
.avatar11-lg {
    @include avatar(11); }
.avatar12-lg {
    @include avatar(12); }
.avatar13-lg {
    @include avatar(13); }
.avatar14-lg {
    @include avatar(14); }
.avatar15-lg {
    @include avatar(15); }
.avatar16-lg {
    @include avatar(16); }
.avatar17-lg {
    @include avatar(17); }
.avatar18-lg {
    @include avatar(18); }
.avatar19-lg {
    @include avatar(19); }
