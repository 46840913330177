@import "colors";
@import "fonts";

html, body {
    height: 100%; }

body {
    background: $gray-lighter url(/img/bg/bg.png);
    font-family: $font; }

a, a:visited, a:focus, a:active, a:hover {
    outline: 0 none !important;
    background-color: transparent /* IE 10 gray active link fix */; }

.ng-cloak, [ng-cloak] {
    display: none !important; }

.invisible {
    visibility: hidden; }

.table-scrollable {
    overflow-x: auto;
    table {
        min-width: 500px; } }

#wrap {
    position: relative;
    min-height: calc(100% - 55px); }

/* NOTE: Any edits here must also be matched against /css/brand.css and galaxy-server web/src/main/resources/cssTemplate/generalBrandingCssTemplate.css */

#contentWrap {
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    margin-left: -520px;
    width: 1040px;
    z-index: -1; }

/* NOTE: Any edits here must also be matched against /css/brand.css and galaxy-server web/src/main/resources/cssTemplate/generalBrandingCssTemplate.css */

#brand-logo {
    display: block;
    height: 60px;
    width: 200px; }

// hide .tab-messaging <iframe>
.tab-messaging {
    position: absolute;
    top: -9999px; }

.footer {
    background: $gray-dark;
    color: $light;
    padding: 5px 0 0;
    font-size: 12px;
    position: relative;
    z-index: 1000;
    height: 55px;

    a {
        color: $gray-lighter;
        text-decoration: none; }

    ul {
        margin-bottom: 3px; }

    .poweredBy {
        background: url(/img/poweredByEgym.png) right no-repeat;
        padding-right: 110px;
        height: 18px;
        margin-bottom: 5px;
        background-size: 100px; }

    span, .poweredByNetpulseOne {
        color: $gray-light; } }

.top-buffer {
    margin-top: 22px; }

.top-buffer-lg {
    margin-top: 44px; }

.np-go-top {
    position: fixed;
    z-index: 0;
    left: 0;
    top: 0;
    display: none;
    width: 65px;
    height: 100%;
    background: url(/img/np-go-top.png) 0 10% no-repeat;
    &:hover {
        background-color: $gray-lighter;
        opacity: .5; } }

div.page-entertainment,
div.page-workouts,
div.page-challenges,
div.page-challenge-detail,
div.page-goal-center {
    position: relative; }
