.profile {
    text-align: center;

    a {
        font-family: $font-condensed;
        font-weight: bold;
        text-decoration: none;

        &.userImg {
            margin: 7px 32px 0; } } }

.np-dashboard-banner {
    display: block;
    background: url(/img/dashboard/300x250.png) no-repeat scroll 50% 0;
    width: 100%;
    height: 250px; }

/* Challenges */
.ch-numbers {
    font-family: $font-condensed;
    font-weight: bold;
    width: 100%;
    text-align: center;
    display: table;

    div {
        font-size: 35px;
        height: 90px;
        color: $gray-dark;
        vertical-align: middle;
        display: table-cell;

        &.big {
            font-size: 50px;
            height: 90px; }

        &.smaller {
            font-size: 18px;
            height: 80px; }

        &.lighter {
            color: $light; } }

    h5 {
        text-transform: uppercase;
        color: $gray-light; } }

.well {
    &.challenge-box {
        margin-bottom: 0;
        border-bottom: 1px solid $gray-light;

        &:last-child {
            margin-bottom: 20px;
            border-bottom: none; } } }

.challenge-box {
    hr {
        margin-top: 0; }

    .ch-image {
        height: 80px;

        img {
            height: 80px;
            width: 80px; } }

    h4 {
        a {
            color: inherit;
            text-decoration: none; } } }

.overlay-data-processing {
    position: fixed;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: rgba(0,0,0, .85);
    display: flex;
    justify-content: center;
    align-items: center;

    .overlay-content {
        text-align: center;
        font-size: 28px;
        color: #ccc;
        max-width: 75%; }

    label {
        cursor: pointer;
        &::before {
            content: '';
            display: inline-block;
            width: 20px;
            height: 20px;
            border: 2px solid #ccc;
            border-radius: 3px;
            background-color: rgba(255,255,255, .3); } }

    input {
        display: none; } }
