/*   Main navigation   */

.navbar {
    min-height: 80px;

    #brand-logo {
        margin-top: 8px; } }

.navbar-form {
    &.navbar-right:last-child {
        margin-right: 0; }

    padding: 0;
    margin: 0;

    .form-group {
        padding-top: 18px;

        .help-block {
            margin: 0;

            a {
                font-size: 12px; } } } }

ul.nav.navbar-nav {
    > li {
        > a {
            line-height: 60px;
            height: 79px;
            padding: 10px 7px;
            font-size: 12px;
            font-weight: bold;

            > i {
                vertical-align: sub; } } } }
